



import Vue from 'vue'
import { mapActions } from 'vuex'

export default Vue.extend({
  mounted() {
    // Destroying cookie and store
    this.$cookies.remove('authToken')
    this.logout()

    // Closing sidebar
    const app = document.getElementById('app')
    app?.classList.remove('sidebar-open-wide', 'sidebar-open')

    // Closing any modal that could be opened
    this.$bvModal.hide('imminentTasksModal')
    this.$bvModal.hide('addTaskModal')

    // Displaying toast
    this.setGlobalToast({
      type: 'danger',
      title: 'Erreur !',
      content: "Votre session n'est plus valide. Merci de vous connecter à nouveau.",
    })

    // Redirecting to home
    this.$router.push({ name: 'home' })
  },
  methods: {
    ...mapActions(['setGlobalToast', 'logout']),
  },
})
